export const ActionTypes =  {
    SET_BANNERS         : "SET_BANNERS",
    SET_TOP_BOOKED_TESTS: "SET_TOP_BOOKED_TESTS",
    SET_TEST_CART_LIST  : "SET_TEST_CART_LIST",
    SET_TEST_DETAILS    : "SET_TEST_DETAILS",
    SET_ALL_TEST_DETAILS: "SET_ALL_TEST_DETAILS",
    REMOVE_TEST_DETAILS : "REMOVE_TEST_DETAILS",
    ADD_TO_CART         : "ADD_TO_CART",
    REMOVE_FROM_CART    : "REMOVE_FROM_CART",
    AUTH_USER           : "AUTH_USER",
    SET_TEST_LOCATION   : "SET_TEST_LOCATION",
    START_LOADING       : "START_LOADING",
    STOP_LOADING        : "STOP_LOADING",
    TEST_FILTERS        : "TEST_FILTERS",
    PACKAGE_FILTERS     : "PACKAGE_FILTERS",
    ORGANS_LIST         : "ORGANS_LIST",
    CONDITIONS_LIST     : "CONDITIONS_LIST",
}