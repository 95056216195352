export const API_URL = {
    ASSETS                     : `${process.env.REACT_APP_BASE_URL}/storage/app/`,
    BANNERS                    : `${process.env.REACT_APP_BASE_URL}/public/api/banners`,
    TOP_BOOKED_TEST            : `${process.env.REACT_APP_BASE_URL}/public/api/topBookedTest`,
    TEST_DETAILS               : `${process.env.REACT_APP_BASE_URL}/api/test`,
    TEST_LISTS                 : `${process.env.REACT_APP_BASE_URL}/api/test-list`,
    BANNER_FROM                : `${process.env.REACT_APP_BASE_URL}/api/bannerContactForm`,
    NEWS_AND_EVENTS            : `${process.env.REACT_APP_BASE_URL}/api/newsAndEvents`,
    REGISTER                   : `${process.env.REACT_APP_BASE_URL}/api/register`,
    LOGIN                      : `${process.env.REACT_APP_BASE_URL}/api/login`,
    UPDATE_BILLING_DETAILS     : `${process.env.REACT_APP_BASE_URL}/api/update-billing-details`,
    SAVE_THE_ORDER             : `${process.env.REACT_APP_BASE_URL}/api/save-payment-order`,
    GET_CUSTOMER_INFO          : `${process.env.REACT_APP_BASE_URL}/api/customer/`,
    PACKAGES_LIST              : `${process.env.REACT_APP_BASE_URL}/api/packages`,
    PATIENTS_CONSUMERS         : `${process.env.REACT_APP_BASE_URL}/api/patients-consumers`,
    FEEDBACKS                  : `${process.env.REACT_APP_BASE_URL}/api/feedback`, 
    FAQ                        : `${process.env.REACT_APP_BASE_URL}/api/faq`,
    HOSPITAL_LAB_MANAGEMENT    : `${process.env.REACT_APP_BASE_URL}/api/hospital-lab-management`,
    CLINICAL_LAB_MANAGEMENT    : `${process.env.REACT_APP_BASE_URL}/api/clinical-lab-management`,
    FRANCHISING_OPPORTUNITIES  : `${process.env.REACT_APP_BASE_URL}/api/franchising-opportunities`,
    RESEARCH                   : `${process.env.REACT_APP_BASE_URL}/api/research`,
    HEALTHCHECKUP_FOR_EMPLOYEES: `${process.env.REACT_APP_BASE_URL}/api/healthcheckup-for-employees`,
    CAPTAINS_HEALTH_CHECK: `${process.env.REACT_APP_BASE_URL}/api/captains-health-check`,
    NIPT: `${process.env.REACT_APP_BASE_URL}/api/nipt`,
    ALLERGY_TESTING: `${process.env.REACT_APP_BASE_URL}/api/allergy-testing`,
    ANAND_DIAGNOSTIC_LABORATORY_SHIVAJINAGAR_BRANCH: `${process.env.REACT_APP_BASE_URL}/api/anand-diagnostic-laboratory-shivajiangar-branch`,
    VITAMIN_TEST: `${process.env.REACT_APP_BASE_URL}/api/vitamin-test`,
    DIABETES_LIPID_PROFILE: `${process.env.REACT_APP_BASE_URL}/api/diabetes-lipid-profile`,
    CBC_ELECTROLYTES_ETC: `${process.env.REACT_APP_BASE_URL}/api/cbc-electrolytes-etc`,
    KFT_CREATININE_THYROID: `${process.env.REACT_APP_BASE_URL}/api/kft-creatinine-thyroid`,
    BOOK_AN_APPOINTMENT        : `${process.env.REACT_APP_BASE_URL}/api/book-an-appointment`,
    UPDATE_MY_PROFILE          : `${process.env.REACT_APP_BASE_URL}/api/update-customer`,
    GET_MY_ORDERS              : `${process.env.REACT_APP_BASE_URL}/api/get-orders/`,
    CHANGE_MY_PASSWORD         : `${process.env.REACT_APP_BASE_URL}/api/change-my-password/`,
    CANCEL_MY_ORDER            : `${process.env.REACT_APP_BASE_URL}/api/cancel-my-order/`,
    GET_LAB_LOCATION           : `${process.env.REACT_APP_BASE_URL}/api/get-lab-location`,
    GET_CITY_MASTER            : `${process.env.REACT_APP_BASE_URL}/api/get-city-master`,
    NEWS_LETTER                : `${process.env.REACT_APP_BASE_URL}/api/news-letter`,
    ORGAN_LIST                 : `${process.env.REACT_APP_BASE_URL}/api/get-organs`,
    CONDITIONS_LIST            : `${process.env.REACT_APP_BASE_URL}/api/get-conditions`,
    FORGOT_PASSWORD            : `${process.env.REACT_APP_BASE_URL}/api/forgot-password`,
    RESET_PASSWORD             : `${process.env.REACT_APP_BASE_URL}/api/reset-password/`,
    LOGIN_WITH_OTP             : `${process.env.REACT_APP_BASE_URL}/api/login-with-otp`,
    JOB_APPLY                  : `${process.env.REACT_APP_BASE_URL}/api/job-apply`,
    ADD_TO_CART                : `${process.env.REACT_APP_BASE_URL}/api/add-to-cart`,
    REMOVE_TO_CART             : `${process.env.REACT_APP_BASE_URL}/api/remove-to-cart/`,
    CUSTOMER_CART_ITEMS        : `${process.env.REACT_APP_BASE_URL}/api/cart-items`,
    REACH_US                   : `${process.env.REACT_APP_BASE_URL}/api/contact-us`,
    // Landing page -> contact page controller function api
    LANDING_PAGES_FORM         : `${process.env.REACT_APP_BASE_URL}/api/landing_pages_form`,
    CAREERS                    : `${process.env.REACT_APP_BASE_URL}/api/current-opening`,
    CAREERS_DEATILS            : `${process.env.REACT_APP_BASE_URL}/api/job-details/`,
    LOCATIONS                  : `${process.env.REACT_APP_BASE_URL}/api/locations`,
} 
